const audioSrc = [
    'https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/739415395%3Fsecret_token%3Ds-ZWiSq&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_artwork=false&show_reposts=false&show_teaser=true',
    'https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/739420966%3Fsecret_token%3Ds-7yoTl&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_artwork=false&show_reposts=false&show_teaser=true',
    'https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/739417699%3Fsecret_token%3Ds-KAbDe&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_artwork=false&show_reposts=false&show_teaser=true',
    'https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/739418572%3Fsecret_token%3Ds-8r9JZ&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_artwork=false&show_reposts=false&show_teaser=true',
    'https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/738758083%3Fsecret_token%3Ds-HKcTM&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_artwork=false&show_reposts=false&show_teaser=true',
    'https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/739414855%3Fsecret_token%3Ds-WvwVP&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_artwork=false&show_reposts=false&show_teaser=true'
]

const videoSrc = [
    'https://www.youtube.com/embed/kMs5o7F1ruM',
    'https://www.youtube.com/embed/CSx1RM6zWJg'
]

export { audioSrc, videoSrc }