const gallery = document.querySelector('#gallery .images')
const lightbox = document.getElementById('lightbox')
const lightboxImages = [].slice.call(lightbox.querySelectorAll('.lightbox-image-container > img'))
const hideLightboxButton = document.getElementById('hide_lightbox')
const nextImage = document.getElementById('next')
const prevImage = document.getElementById('prev')

let currentTarget

function getImageTarget(img) {
    currentTarget = img.getAttribute('data-target')
}

function showImg() {
    lightboxImages.forEach(img => img.style.display = 'none')
    lightboxImages[currentTarget].style.display = 'block'
}

gallery.addEventListener('click', e => {
    if (e.target.tagName === 'IMG') {
        getImageTarget(e.target)
        showImg()
        lightbox.style.display = 'flex'
    }
})

hideLightboxButton.addEventListener('click', () => {
    lightbox.style.display = 'none'
})

nextImage.addEventListener('click', () => {
    const numOfImages = document.querySelectorAll('.img-container').length-1
    if (currentTarget == numOfImages) {
        currentTarget = 0
    } else {
        currentTarget++
    }
    showImg()
})

prevImage.addEventListener('click', () => {
    const numOfImages = document.querySelectorAll('.img-container').length-1
    if (currentTarget == 0) {
        currentTarget = numOfImages
    } else {
        currentTarget--
    }
    showImg()
})