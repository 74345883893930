const slider = document.querySelector('.upcoming-slider')
const appointments = [].slice.call(slider.querySelectorAll('.upcoming-appointment'))
const prevSlide = document.getElementById('prevSlide')
const nextSlide = document.getElementById('nextSlide')

let currentSlide = 0

if (appointments.length === 1) nextSlide.style.visibility = 'hidden'

nextSlide.addEventListener('click', () => {
    prevSlide.style.visibility = 'visible'
    const appointmentWidth = appointments[0].clientWidth
    currentSlide++
    if (currentSlide === appointments.length-1) {
        nextSlide.style.visibility = 'hidden'
    }
    slider.style.transform = `translateX(${(-currentSlide)*appointmentWidth}px)`
})

prevSlide.addEventListener('click', () => {
    nextSlide.style.visibility = 'visible'
    const appointmentWidth = appointments[0].clientWidth
    currentSlide--
    if (currentSlide === 0) {
        prevSlide.style.visibility = 'hidden'
    }
    slider.style.transform = `translateX(${(-currentSlide)*appointmentWidth}px)`
})