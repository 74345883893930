import ScrollMagic from "scrollmagic"
import { TweenLite, TimelineLite, CSSPlugin, Linear } from 'gsap'
import "scrollmagic/scrollmagic/minified/plugins/animation.gsap.min.js"
import { audioSrc, videoSrc } from './scripts/srcData'
import { populateMedia } from './scripts/mediaFunctions'
import './scripts/galleryScript'
import './scripts/menuScript'
import './scripts/sliderScript'
import './scripts/sendMail'

let loaded = 0
let loadingTimeout = 0

const controller = new ScrollMagic.Controller();

window.addEventListener('load', () => {
    const upcomingSlider = document.querySelector('.upcoming-slider')
    const appointments = [].slice.call(upcomingSlider.querySelectorAll('.upcoming-appointment'))

    upcomingSlider.style.width = appointments.length*100 + '%'

    if (window.innerWidth < 900) {
        mobile()
        populateMedia()
    } else {
        const h1Wrapper = document.querySelector('h1')
        h1Wrapper.innerHTML = h1Wrapper.textContent.replace(/\S/g, "<span class='letter'>$&</span>");

        let tl = new TimelineLite();

        CSSPlugin.defaultTransformPerspective=1000;

        tl  
            .set(h1Wrapper, { letterSpacing: '1px', visibility: 'visible' })
            .staggerFromTo('.letter', 2.5, { autoAlpha: 0 }, { autoAlpha: 1, ease: Power2.easeOut }, 0.1).delay(0.5)
            .to(h1Wrapper, 2, { letterSpacing: '25px', autoAlpha: 0.4, ease: Power2.easeOut, autoRound:false }, '-=2.3')
            .to('.titles-container h2', .7, { autoAlpha: 1, rotationX: 0, ease: Power2.easeOut, onComplete: () => {
                populateMedia()
            } }, '-=0.5')

            const int = setInterval(() => {
                if (loaded === videoSrc.length + audioSrc.length || loadingTimeout === 4000) {
                    clearInterval(int)
                    tl
                        .to('.titles-container', .5, { scaleX: 0.24, scaleY: 0.24, left: 110, top: 22 }, '+=1')
                        .to('.overlay', .7, { bottom: '100%', ease: Power2.easeInOut })
                        .set('header', { zIndex: 1500 })
                        .from('.upcoming', .7, { left: '2%', autoAlpha: 0, ease: Power2.easeOut, onComplete: () => {
                            const scene1 = new ScrollMagic.Scene({
                                triggerElement: '#biography',
                                triggerHook: 0.9
                            })
                                .setTween(TweenLite.to('.upcoming', .7, { left: '2%', autoAlpha: 0, ease: Power2.easeOut }))
                                .addTo(controller)
            
                        } })
                        .set('nav', { display: 'block' })
                        .set('html', { overflowY: 'scroll' })
                }
                loadingTimeout += 100
            }, 100)
    }
}) 

const scene2 = new ScrollMagic.Scene({
    triggerElement: '#biography',
    triggerHook: 0,
    offset: -46
})
    .setClassToggle('header', 'navbar')
    .addTo(controller)

const scene3 = new ScrollMagic.Scene({
    triggerElement: '#biography',
    triggerHook: 0.5
})
    .setTween(new TimelineLite().staggerFrom('#biography p', .7, {  y: -40, autoAlpha: 0, ease: Power2.easeOut }, 0.2))
    .addTo(controller)

scene3.reverse(false)

const scene4 = new ScrollMagic.Scene({
    triggerElement: '#gallery',
    triggerHook: 1,
    offset: 60,
    duration: '100%'
})
    .setPin('#biography', { pushFollowers: false })
    .addTo(controller)

const scene5 = new ScrollMagic.Scene({
    triggerElement: '#gallery',
    triggerHook: 0.5
})
    .setTween(new TimelineLite().staggerFrom('.col-left .img-container', .72, {  x: -100, autoAlpha: 0, ease: Power2.easeInOut }, 0.12))
    .addTo(controller)

scene5.reverse(false)

const scene6 = new ScrollMagic.Scene({
    triggerElement: '#gallery',
    triggerHook: 0.5
})
    .setTween(new TimelineLite().staggerFrom('.col-right .img-container', .72, {  x: 100, autoAlpha: 0, ease: Power2.easeInOut }, 0.12))
    .addTo(controller)

scene6.reverse(false)

const scene7 = new ScrollMagic.Scene({
    triggerElement: '#media',
    triggerHook: 1,
    offset: 60,
    duration: '100%'
})
    .setPin('#gallery', { pushFollowers: false })
    .addTo(controller)

const audios = [].slice.call(document.querySelectorAll('.audio iframe'))
audios.forEach((audio, i) => {
    const scene = new ScrollMagic.Scene({
            triggerElement: audio,
            triggerHook: 0.95
        })
            .setTween(TweenLite.from(audio, .85, { scale: 0, ease: Elastic.easeOut.config(1.02,0.97) }))
            .addTo(controller)

    scene.reverse(false)
})

const videos = [].slice.call(document.querySelectorAll('.video iframe'))
videos.forEach((video, i) => {
    let x 
    i % 2 > 0 ? x = 200 : x = -200
    const scene = new ScrollMagic.Scene({
            triggerElement: video,
            triggerHook: 0.8
        })
            .setTween(TweenLite.from(video, 2, { x, autoAlpha: 0, ease: Power4.easeOut }))
            .addTo(controller)

    scene.reverse(false)
})

const scene8 = new ScrollMagic.Scene({
    triggerElement: '#contact',
    triggerHook: 0.6
})
    .setTween(TweenLite.from('.contact-container', 0.8, { y: -1000, ease: Elastic.easeOut.config(1, 0.6) }).delay(0.5))
    .addTo(controller)

scene8.reverse(false)

const scene9 = new ScrollMagic.Scene({
    triggerElement: '#contact',
    triggerHook: 0
})
    .setTween(new TimelineLite()
        .to('header', 0.1, { y: '-100%', ease: Linear.easeNone })
        .to('.titles-container', 0.1, { y: '-100%', ease: Linear.easeNone }, '-=0.1')
    )
    .addTo(controller)

function mobile() {
    controller.enabled(false)
    TweenLite.set('#biography', { clearProps: 'all' })
    TweenLite.set('#contact', { clearProps: 'all' })
    TweenLite.set('#gallery', { clearProps: 'all' })
    TweenLite.set('#media', { clearProps: 'all' })
    TweenLite.set('.upcoming', { clearProps: 'all' })
    TweenLite.set('.contact-container', { clearProps: 'all' })

    document.querySelectorAll('.video iframe').forEach(video => {
        TweenLite.set(video, { clearProps: 'all' })
    })
    document.querySelectorAll('.audio iframe').forEach(audio => {
        TweenLite.set(audio, { clearProps: 'all' })
    })

    scene4.removePin(true)
    scene7.removePin(true)

    document.querySelectorAll('.col-left .img-container').forEach(imgContainer => {
        TweenLite.set(imgContainer, { clearProps: 'all' })
    })
    document.querySelectorAll('.col-right .img-container').forEach(imgContainer => {
        TweenLite.set(imgContainer, { clearProps: 'all' })
    })
    document.querySelectorAll('#biography p').forEach(p => {
        TweenLite.set(p, { clearProps: 'all' })
    })
}

let prevSize = window.innerWidth

window.addEventListener("resize", e => {
    if (window.innerWidth < 900) {
        mobile()               
    } else {
        if (prevSize < 900) {
            location.reload()
            window.scrollTo(0,0)
        }
    }
    prevSize = window.innerWidth
});

window.addEventListener('orientationchange', () => {
    if (window.innerWidth < 900) {
        mobile()
    }
})