import axios from 'axios'
const form = document.forms[0]

form.addEventListener('submit', e => {
    e.preventDefault()
    const name = document.getElementById('name')
    const email = document.getElementById('email')
    const subject = document.getElementById('subject')
    const message = document.getElementById('message')

    const url = `https://peixinlee.com/sendMail?name=${name.value}&email=${email.value}&subject=${subject.value}&message=${message.value}`

    axios.get(url)
        .then(res => {
            alert('Your message has been sent.')
            name.value = ''
            email.value = ''
            subject.value = ''
            message.value = ''
        })
        .catch(() => alert('An error occurred while sending the message.'))
})