const menuToggler = document.querySelector('.menu-toggler')
const nav = document.querySelector('nav')
const headerHeight = 46

menuToggler.addEventListener('click', () => {
    const tween1 = TweenLite.to(menuToggler, .7, { autoAlpha: 0, ease: Power2.easeOut })
    const tween2 = TweenLite.to('nav', .7, { x: '0%', autoAlpha: 0.8, ease: Power2.easeOut })
    const tween3 = TweenLite.to('.menu-overlay', .7, { autoAlpha: 0.8, ease: Power2.easeOut })

})

nav.addEventListener('click', e => {
    const coverHeight = document.getElementById('cover').clientHeight
    const biographyHeight = document.getElementById('biography').clientHeight
    const galleryHeight = document.getElementById('gallery').clientHeight
    const mediaHeight = document.getElementById('media').clientHeight

    if (e.target.tagName === 'IMG' || e.target.tagName === "LI") {
        const tween1 = TweenLite.to(menuToggler, .7, { autoAlpha: 1, ease: Power2.easeOut })
        const tween2 = TweenLite.to('nav', .7, { x: '100%', autoAlpha: 0, ease: Power2.easeOut })
        const tween3 = TweenLite.to('.menu-overlay', .7, { autoAlpha: 0, ease: Power2.easeOut })
    }

    if (e.target.id === "toTop") window.scrollTo(0, 0)
    else if (e.target.id === "toBio") window.scrollTo(0, coverHeight - headerHeight)
    else if (e.target.id === "toGallery") { 
        window.scrollTo(0, coverHeight + biographyHeight - headerHeight)
    } else if (e.target.id === "toMedia") {
        window.scrollTo(0, coverHeight + biographyHeight + galleryHeight + - headerHeight)
    } else if (e.target.id === "toContact")  { 
        window.scrollTo(0, coverHeight + biographyHeight + galleryHeight + mediaHeight - headerHeight)
    }
})  

