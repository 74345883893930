import { audioSrc, videoSrc } from './srcData'

let audioIframes = [].slice.call(document.querySelectorAll('.audio iframe'))
let videoIframes = [].slice.call(document.querySelectorAll('.video iframe'))
function populateMedia() {
    audioIframes.forEach((audio, i) => {
        audio.src=audioSrc[i]
    })

    videoIframes.forEach((video, i) => {
        video.src=videoSrc[i]
    })
}

export { populateMedia }